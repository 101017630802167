import React from 'react';

const Time = ({ period, seconds }) => {
	const time_str = period === 2 ? 'HT' : period === 4 ? 'FT' : `${Math.floor(seconds / 60)}'`;

	return <span className="fw-bold text-warning">
		{time_str}
	</span>;
};

export default Time;

import React, { Fragment } from 'react';
import { useEffect } from 'react';
import {
	BookingCards,
	GoalGoal,
	MatchResult,
	NextGoal,
	OverUnder,
	Period,
	Scores,
	Time,
} from './index';

import {
	getHalfTimeOddsMatchResult,
	get_red_cards
} from '../functions';

const is_dangerous_attack = st => st === "11008" || st === "21008";
const is_goal = st => st === "10003" || st === "20003";

const GridRow = ({ event }) => {
	const game = event.obj[0];

	const { length: totalHalfTimeOdds } = getHalfTimeOddsMatchResult(game.bt);
	const [t1_red, t2_red] = get_red_cards(game.sc);
	const total_goals = game.sc.GOAL[0] + game.sc.GOAL[1];

	return (
		<tr key={game.std} data-match-time={game.tss}>
			<td>{game.cd}</td>
			<td>
				<Period period={game.p} />
			</td>
			<td>
				<Time period={game.p} seconds={game.tm} />
			</td>
			<td>
				<span>{game.t1.n} <BookingCards cardType="RED_CARD" totalCards={t1_red} /></span>
				<br />
				<span>{game.t2.n} <BookingCards cardType="RED_CARD" totalCards={t2_red} /></span>
			</td>
			<td className='text-center'>
				<Scores scores={game.sc} totalHalfTimeOdds={totalHalfTimeOdds} period={game.p} />
			</td>
			{is_goal(game.st) ? <td className="goalCss" colSpan="11">{game.stn}</td> :
				is_dangerous_attack(game.st) ? <td className="dangerCss" colSpan="11">{game.stn}</td>:
					<Fragment>
						<MatchResult blocked={game.b} odds={game.bt} />
						<OverUnder blocked={game.b} odds={game.bt} />
						<GoalGoal blocked={game.b} odds={game.bt} />
						<NextGoal blocked={game.b} odds={game.bt} totalGoals={total_goals} />
					</Fragment>
			}
		</tr>
	);
};

export default GridRow;
